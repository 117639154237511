@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./fonts/SF-Pro-Rounded-Heavy.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./fonts/SF-Pro-Rounded-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SF Pro Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  background-color: #1e90ff;
  min-height: 100vh;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.brand {
  color: white;
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 0;
  padding-top: 30px;
  position: fixed;
  top: 0;
}

.content {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

h1 {
  color: white;
  font-size: 72px;
  max-width: 700px;
  font-weight: 800;
  margin-bottom: 50px;

  text-align: center;
}

.form-group {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}

input {
  padding: 20px 25px;
  border-radius: 100px;
  border: 2px solid transparent;
  font-size: 22px;
  font-weight: 500;
  flex: 1;
  font-family: 'SF Pro Rounded', sans-serif;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

input:focus {
  outline: none;
}

input:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

@keyframes shimmer {
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
}

button {
  padding: 22px 35px;
  border-radius: 100px;
  border: none;
  background: linear-gradient(
    110deg,
    #2a2a2a 47%, 
    #3a3a3a 48%,
    #3a3a3a 52%,
    #2a2a2a 53%
  );
  background-size: 200% auto;
  animation: shimmer 6s linear infinite;
  color: white;
  font-size: 22px;
  font-weight: 800;
  cursor: pointer;
  transition: transform 0.2s ease;
  font-family: 'SF Pro Rounded', sans-serif;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

button:hover {
  transform: scale(1.02);
}

button:active {
  transform: translateY(1px);
}

@media (max-width: 600px) {
  h1 {
    font-size: 54px;
    margin-bottom: 40px;
    width: 100%;
    padding: 0 20px;
  }
  
  .brand {
    font-size: 32px;
    margin-bottom: 40px;
  }
  
  .content {
    padding: 20px 15px;
  }
  .container {
    margin-right: 20px;
    margin-left: 20px;
    justify-content: flex-start;
    padding-top: 60px;
  }

  .form-group {
    flex-direction: column;
    gap: 12px;
  }

  input, button {
    width: 100%;
    box-sizing: border-box;
  }

  .container {
    padding: 20px 0;
  }

  .content {
    padding: 0;
  }

  .form-group {
    padding: 0;
    max-width: 100%;
  }

  input, button {
    width: 100%;
    box-sizing: border-box;
  }

  form {
    width: 100%;
    box-sizing: border-box;
  }

  .brand {
    position: relative;
    margin-bottom: 40px;
  }
  
  .content {
    min-height: auto;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 46px;
  }
  
  .brand {
    font-size: 28px;
    margin-bottom: 30px;
  }
}